

































































import {forEach} from '@labor-digital/helferlein';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {isUndefined} from '@labor-digital/helferlein/lib/Types/isUndefined';
import {Branch} from '../../../Interface/Branch';
import {BranchAndGeoLocationService} from '../../../Service/BranchAndGeoLocationService';
import Btn from '../../Misc/Btn/Btn.vue';
import Icon from '../../Misc/Icon/Icon.vue';
import LinkWrap from '../../Misc/Link/LinkWrap/LinkWrap.vue';
import MailWrap from '../../Misc/Link/MailWrap/MailWrap.vue';

export default {
    name: 'CurrentBranch',
    components: {MailWrap, Btn, Icon, LinkWrap},
    props: {
        branch: {
            type: Object,
            default: () => {}
        },
        override: {
            type: Object
        },
        companyColor: {
            type: Boolean,
            default: true
        },
        btnLink: String,
        showFindOther: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        branchInternal(): Branch
        {
            if (!isEmpty(this.branch)) {
                return this.branch;
            }
            return BranchAndGeoLocationService.getCurrentBranch();
        },
        branchLink(): string
        {
            return BranchAndGeoLocationService.getContextualBranchLink(this.branchInternal);
        },
        hasButton(): boolean
        {
            return !isUndefined(this.btnLink);
        },
        phoneNumber(): string
        {
            if (isUndefined(this.branchInternal.phone) && isUndefined(this.override?.phone)) {
                return null
            }

            if (!isUndefined(this.override?.phone)) {
                return  this.branchInternal.phone.slice(0, -1) + this.override?.phone;
            }

            return this.branchInternal.phone;
        },
        hasFax(): boolean
        {
            return !isUndefined(this.branchInternal.fax);
        },
        mailAddress(): string
        {
            if (isUndefined(this.branchInternal.email) && isUndefined(this.override?.email)) {
                return null
            }

            return this.override?.email ?? this.branchInternal.email;
        },
        hasBranch(): boolean
        {
            return !isEmpty(this.branchInternal);
        },
        addressCity(): string
        {
            if (isUndefined(this.branchInternal.zip)) {
                return;
            }
            return !isEmpty(this.branchInternal.district) ?
                    this.branchInternal.zip + ' ' + this.branchInternal.city + ' - ' + this.branchInternal.district
                    : this.branchInternal.zip + ' ' + this.branchInternal.city;
        },
        isCompanyColor(): Object
        {
            return {
                'currentBranch__name--color': this.companyColor
            };
        },
        branchSearchLink(): string
        {
            return BranchAndGeoLocationService.getBranchSearchLink();
        }
    },
    methods: {
        openSelectBranchPage()
        {
            BranchAndGeoLocationService.openSelectBranchPage();
        },

        mergeOpeningHours(): { days: string, times: string, label: string }[]
        {
            // Sort the data by day of the week
            const days = {
                openMo: !isEmpty(this.branchInternal.openMo) ? this.branchInternal.openMo : '08:00 - 17:00',
                openTu: !isEmpty(this.branchInternal.openTu) ? this.branchInternal.openTu : '08:00 - 17:00',
                openWe: !isEmpty(this.branchInternal.openWe) ? this.branchInternal.openWe : '08:00 - 17:00',
                openTh: !isEmpty(this.branchInternal.openTh) ? this.branchInternal.openTh : '08:00 - 17:00',
                openFr: !isEmpty(this.branchInternal.openFr) ? this.branchInternal.openFr : '08:00 - 16:00',
                openSa: this.branchInternal.openSa,
                openSu: this.branchInternal.openSu
            };

            let merged = [];
            let current = null;

            forEach(days, (time, day) => {
                if (isEmpty(time)) {
                    current = null;
                    return;
                }

                if (current && current.times === time) {
                    // Merge days
                    current.days = `${current.days.split('-')[0]}-` + this.$t(`branchSearch.detail.${day}`);
                } else {
                    // Start a new entry
                    current = {
                        days: this.$t(`branchSearch.detail.${day}`),
                        times: time,
                        label: this.$t(`branchSearch.detail.openingTimes`, [time])
                    };
                    merged.push(current);
                }
            });

            return merged;
        }
    }
};
