/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.05.22 at 13:13
 */

import {formatDateAndTime} from '@labor-digital/helferlein/lib/FormatAndConvert/formatDateAndTime';
import {AppContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/AppContext';

/**
 * Helper service to format date values
 */
export class DateFormatter
{
    /**
     * The main context to resolve the requirements with
     */
    protected static _context: AppContext;

    /**
     * Initializes the service by providing the app context
     *
     * @param context
     */
    public static initialize(context: AppContext): void
    {
        this._context = context;
    }

    /**
     * Get a string with a speaking date value like "a week ago"
     *
     * @param date
     */
    public static formatRelativeDate(date: string)
    {
        return this._context.translation.translate('dates.relative.' + this.relative(date));
    }

    /**
     * Get a string with a speaking date value like "from now on" or a localized date string
     *
     * @param date
     */
    public static formatStartDate(date: string)
    {
        let from = new Date(date),
            now = new Date();

        if (from <= now) {
            return this._context.translation.translate('dates.now');
        }

        return this.format(date);
    }

    /**
     * Get a localized, formatted date string
     *
     * @param date
     */
    public static format(date: string)
    {
        // @todo Localize the date format!
        return formatDateAndTime('d. F Y', new Date(date));
    }

    /**
     * Get a relative date key like "yesterday" or "lastWeek"
     *
     * @param date
     */
    protected static relative(date: string)
    {
        let from = new Date(date),
            delta = Math.round((
                                   +new Date - (from as any)
                               ) / 1000),
            day = 60 * 60 * 24,
            key = 'old';

        if (delta < day ) {
            key = 'today';
        } else if(delta < day * 2) {
            key = 'yesterday';
        } else if (delta < day * 8) {
            key = 'lastWeek';
        } else if (delta < day * 15) {
            key = 'twoWeeks';
        } else if (delta < day * 22) {
            key = 'threeWeeks';
        } else if (delta < day * 32) {
            key = 'old';
        }

        return key;
    }
}
